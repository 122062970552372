/*----------------------------------------------------*/
/* Variables
/*----------------------------------------------------*/
$navbar-height: 90px;
$navbar-padding-vertical: 15px;
$navbar-padding-horizontal: 15px;
$navbar-default-bg: transparent;
$navbar-default-link-color: #3a3a3a;
$navbar-default-link-hover-color: #fc8301;
$nav-link-padding: 5px;
$navbar-margin-bottom: 0;
$brand-primary: darken(#fc8301, 6.5%);

/*----------------------------------------------------*/
/* Libraries
/*----------------------------------------------------*/
@import 'bootstrap-sass/assets/stylesheets/bootstrap';

/*----------------------------------------------------*/
/* Defaults
/*----------------------------------------------------*/
html {
  height: 100%;
}

body {
  min-height: 100%;
  background: #FFFFFF url('/site/images/bg-body.jpg') repeat center top;
  font-family: 'Verdana', sans-serif;
  position: relative;
}

.body-wrapper {
  opacity: 0;
}

/*----------------------------------------------------*/
/* Header
/*----------------------------------------------------*/
.navbar-header {
  border-bottom: 1px solid #FFFFFF;
  @media (min-width: $grid-float-breakpoint) {
    float: none;
  }
}

.navbar-brand {
  width: 180px;
  @media (min-width: $grid-float-breakpoint) {
    width: 264px;
    height: 121px;
    img {
      padding: 20px 55px 0 0;
    }
  }
}

.navbar-nav > li > a {
  //text-transform: uppercase;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  transition: color .5s ease;
}

.navbar-right {
  @media (min-width: $grid-float-breakpoint) {
    margin-right: 0;
  }
}

.nav-main {
  @media (min-width: $grid-float-breakpoint) {
    margin-top: 10px;
    margin-bottom: 8px;

    > li:last-child > a {
      padding-right: 0;
    }
  }
}

.nav-languages {
  > li > a {
    text-transform: none;
    font-size: 14px;
  }

  @media (min-width: $grid-float-breakpoint) {
    margin-top: -120px;

    > li > a {
      padding-left: 3px;
      padding-right: 3px;
    }

    > li > a::before {
      content: " | ";
      color: #fc8301;
      padding-right: 2px;
    }

    > li:first-child > a::before {
      content: "";
    }

    > li:last-child > a {
      padding-right: 0;
    }
  }
}

/*----------------------------------------------------*/
/* Banners
/*----------------------------------------------------*/
.big-banner {
  width: 100%;
  height: 600px;
  .big-banner__clip,
  .big-banner__item {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .big-banner__description {
    color: #FFF;
    position: absolute;
    font-size: 28px;
    bottom: 42%;
    text-align: center;
    width: 100%;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
  }
}

/*----------------------------------------------------*/
/* Footer
/*----------------------------------------------------*/
.footer {
  padding: 25px 0;
  text-align: center;
  position: relative;
}

.footer__social {
  padding-bottom: 15px;
  position: relative;
  z-index: 502;

  a {
    display: inline-block;
  }

  a:first-child {
    margin-right: 10px;
  }

  @media (min-width: $screen-md-min) {
    padding-bottom: 0;

    a {
      float: left;
    }
  }
}

.footer__text {
  font-size: 12px;
  position: relative;
  z-index: 502;
  margin-top: 3px;

  @media (min-width: $screen-md-min) {
    float: left;
    margin-left: 25px;
  }

  @media (min-width: $screen-lg-min) {
    margin-left: 30px;
  }

  @media (min-width: 1400px) {
    margin-left: 50px;
  }

  span {
    color: $navbar-default-link-hover-color;
  }
}

.footer__logo {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 501;
  opacity: .2;
  @media (min-width: $grid-float-breakpoint) {
    opacity: .5;
  }
  @media (min-width: $screen-md-min) {
    opacity: 1;
  }
}

.footer__banner {
  position: absolute;
  z-index: 502;
  top: -106px;
  right: 10px;
  @media (min-width: $screen-md-min) {
    right: 100px;
  }
  @media (min-width: $screen-lg-min) {
    right: 200px;
  }
}

/*----------------------------------------------------*/
/* Content
/*----------------------------------------------------*/
.main {
  padding: 10px 0 50px;
  background: transparent url('/site/images/bg-content.png') repeat center top;
  background-position: center -177px;
}

.pagina_hr {
  margin-top: 30px;
  margin-bottom: 40px;
  border-top: 1px solid #fff;
}

.isotope-wrap {
  padding: 40px 10px 0 10px;
}

.isotope-item {
  display: block;
  transition: .3s all ease
}

.isotope-item img {
  padding: 3px
}

.form_reserva {
  .row {
    margin-left: -6px;
    margin-right: -6px;
  }
  .col-xs-12,
  .col-xs-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
}
